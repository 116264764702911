import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({
  data: {
    contentfulText: {
      content: {
        // text,
        childMarkdownRemark: { html },
      },
    },
  },
}) => (
  <Layout title="">
    <SEO title="Home" />
    <div
      css={css`
        padding: 3% 0%;
        line-height: 2em;
        font-size: 15px;
        color: #737373;

        > p:nth-child(1),
        > p:nth-child(2) {
          margin-top: 0px;
        }

        > p {
          margin-top: 50px;
        }

        > ul {
          padding: 0;
        }

        li {
          list-style: none;
          padding: 0.5% 0%;
          > p {
            margin: 5px auto;
          }
        }

        :lang(ar) {
          li {
            font-size: 14px;
          }
          > ul {
            padding: 10px 0px;
          }
        }
      `}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </Layout>
)

About.propTypes = {
  data: PropTypes.shape({
    contentfulText: PropTypes.shape({
      content: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query cv($lang: String = "en-US") {
    contentfulText(textId: { eq: "cv" }, node_locale: { eq: $lang }) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default About
